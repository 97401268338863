import React, { useEffect, useState } from 'react';
import { Grid, Accordion, AccordionSummary, AccordionDetails, Typography, Divider } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getCalandar } from '../services/InboxService';
import { getDayWithSuffix } from './CalanderLogic';
import Noti from './Noti'; // Import the 'Noti' component
import Remi from './Remi'; // Import the 'Remi' component

const CalendarOnlyData = ({ realUser }) => {
  const now = new Date();
  const [currentMonth, setCurrentMonth] = useState(now.getMonth() + 1);
  const [currentYear, setCurrentYear] = useState(now.getFullYear());
  const [monthNamen, setMonthNamen] = useState(now.toLocaleString('default', { month: 'long' }));
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [date, setDate] = useState(new Date());
  const [calandar, setCalandar] = useState([]);

  useEffect(() => {
    const fetchCalendarData = async () => {
      try {
        const data = { user: realUser };
        const inboxes = await getCalandar(data);

        // Safely access the calendar data
        const calandarInfo = inboxes?.[0]?.calander || [];
        setCalandar(calandarInfo);
      } catch (error) {
        console.error('Error fetching calendar data:', error);
      }
    };

    fetchCalendarData();
  }, [realUser]);

  const handleDialogOpen = (day) => {
    setIsDialogOpen(true);
    setDate(new Date(currentYear, currentMonth - 1, day));
  };

  const getDaysInMonth = (month, year) => {
    return new Date(year, month, 0).getDate();
  };

  const getMonthDays = (month, year) => {
    const daysInMonth = getDaysInMonth(month, year);
    return Array.from({ length: daysInMonth }, (_, i) => i + 1);
  };

  const monthDays = getMonthDays(currentMonth, currentYear);

  return (
    <Grid container spacing={1} style={{ marginBottom: '0.5rem', marginLeft: '0.5rem' }}>
      {monthDays.map((day) => {
        // Filter calendar entries for the current day
        const dayEntries = calandar.filter((entry) => {
          const entryDate = new Date(entry.date);
          return (
            entryDate.getDate() === day &&
            entryDate.getMonth() + 1 === currentMonth &&
            entryDate.getFullYear() === currentYear
          );
        });

        if (dayEntries.length > 0) {
          return (
            <Grid
              item
              key={day}
              style={{ justifyContent: 'center', marginLeft: 'auto', marginRight: 'auto' }}
            >
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  onClick={() => handleDialogOpen(day)}
                >
                  <Typography variant="body1">
                    {getDayWithSuffix(day)} {monthNamen}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {dayEntries.map((entry) => (
                    <div key={entry.date}>
                      <Typography variant="body1" color="textSecondary">
                        {entry.title}
                      </Typography>
                      <Noti date={entry.date} message={entry.title} />
                      <Remi date={entry.date} message={entry.title} />
                      <Divider />
                    </div>
                  ))}
                </AccordionDetails>
              </Accordion>
            </Grid>
          );
        } else {
          return null; // Skip rendering if there are no entries for the day
        }
      })}
    </Grid>
  );
};

export default CalendarOnlyData;
