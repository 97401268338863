import React, { useContext, useState } from 'react';
import { TaskContext } from './TaskContext';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  width: 1,
});

const PdfUploader = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const { tasks, updatePdfStatus } = useContext(TaskContext);
  const [pdfLink, setPdfLink] = useState("");

  const uploadPdf = async (taskId) => {
    if (!selectedFile || selectedFile.type !== 'application/pdf') {
      console.error('Invalid or missing PDF file.');
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("filename", selectedFile.name);
    formData.append("type", selectedFile.type);
    formData.append("size", selectedFile.size);
    formData.append("lastModified", selectedFile.lastModified);
    formData.append("taskId", taskId);

    try {
      const response = await fetch('https://localprojectapi-api.vercel.app/file/upload', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        const success = await response.json();
        setPdfLink(success.link); // Assuming API returns a `link` field
        updatePdfStatus(taskId, true); // Update task PDF status
        console.log('PDF uploaded successfully:', success);
      } else {
        console.error('Error uploading PDF:', response.statusText);
      }
    } catch (error) {
      console.error('Error uploading PDF:', error);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file?.type === 'application/pdf') {
      setSelectedFile(file);
    } else {
      console.error('Please select a valid PDF file.');
    }
  };

  const attachToTask = (taskId) => {
    uploadPdf(taskId);
  };

  return (
    <div>
      <Button
        component="label"
        variant="contained"
        startIcon={<CloudUploadIcon />}
      >
        Upload File
        <VisuallyHiddenInput type="file" accept=".pdf" onChange={handleFileChange} />
      </Button>

      <div style={{ marginTop: '20px' }}>
        {selectedFile ? (
          <embed src={URL.createObjectURL(selectedFile)} width="100%" height="500px" />
        ) : (
          <p>No file selected</p>
        )}
      </div>

      {tasks.map((task) => (
        <div key={task.id}>
          <Button
            variant="contained"
            color="primary"
            style={{ marginTop: '10px', marginBottom: '5px' }}
            onClick={() => attachToTask(task.id)}
            disabled={!selectedFile} // Disable button if no file is selected
          >
            Attach to Task {task.id}
          </Button>
        </div>
      ))}
    </div>
  );
};

export default PdfUploader;
