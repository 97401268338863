import React, { useState, useEffect } from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ButtonGroup from '@mui/material/ButtonGroup'
import Button from '@mui/material/Button'
import HomeIcon from '@mui/icons-material/Home'
import InfoIcon from '@mui/icons-material/Info'
import LoginIcon from '@mui/icons-material/Login'
import ContactSupportIcon from '@mui/icons-material/ContactSupport'
import SimpleSlide from './SimpleSlide'
import AndroidIcon from '@mui/icons-material/Android'
import PolicyIcon from '@mui/icons-material/Policy'
import SearchSkills from './SearchSkills'
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom'
import AppleSpinner from '../components/AppleSpinner';
const HomePageBar = () => {
  const nav = useNavigate();
  const [showButton, setShowButton] = useState(false)
  const [isServerUp] = useState(true);
  const [anchorElNav, setAnchorElNav] = useState(null)
    const [isOpen, setIsOpen] = useState(false);
    const handleOpenNavMenu = (event) => {
      setIsOpen(true);
      setAnchorElNav(event.currentTarget) 
    };
    const handleCloseNavMenu = () => {
      setIsOpen(false);
      setAnchorElNav(null) 
    };
    useEffect(() => {
      const handleDocumentClick = (event) => {
        if (isOpen && !event.target.closest('#menu-appbar')) {
          // Clicked outside the menu, so close it.
          setIsOpen(false);
        }
      };
      if (isOpen) {
        document.addEventListener('click', handleDocumentClick);
      } else {
        document.removeEventListener('click', handleDocumentClick);
      }
      return () => {
        // Clean up event listener when component unmounts.
        document.removeEventListener('click', handleDocumentClick);
      };
    }, [isOpen]);

  const toggleButton = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    };
  }, [])

  const scrollTop = () => { window.scrollTo({ top: 0, behavior: 'smooth' }) }
  return (
    <>  
      <AppBar position="static" style={{ backgroundColor: "#e4f3e0"}}>
      <AppleSpinner style={{}}></AppleSpinner>
      <div >
        <Toolbar disableGutters style={{ maxHeight: "100px", width:"100%" }}>
        <Button style={{color:"#444444"}} aria-labelledby="Home" variant="text" onClick={() => nav('/')}><HomeIcon label="Members" /></Button>
          <Box  sx={{
              display: { xs: 'none', md: 'flex', justifyContent: 'center' },
            }}>
            <div
              onClick={handleCloseNavMenu}
              sx={{ my: 2, display: 'block'}}>
              <Button style={{color:"#444444"}} size="large" aria-labelledby="App" variant="text" onClick={() => nav('/SneakPeak')}>App</Button>
              <Button style={{color:"#444444"}} size="large" aria-labelledby="Blogs" variant="text" onClick={() => nav('/Blogs')}>Blogs</Button>
              <Button style={{color:"#444444"}} size="large" aria-labelledby="Jobs" variant="text" onClick={() => nav('/Jobs')}>Jobs</Button>
              <Button style={{color:"#444444"}} size="large" aria-labelledby="Rewards" variant="text" onClick={() => nav('/GainRewards')}>Rewards</Button>
              <Button style={{color:"#444444"}} size="large" aria-labelledby="Mission" variant="text" onClick={() => nav('/OurMission')}>Mission</Button>
              <Button style={{color:"#444444"}} size="large" aria-labelledby="Tweets" variant="text" onClick={() => nav('/Tweets')}>Tweets</Button>
              <Button style={{color:"#444444"}} size="large" aria-labelledby="Products" variant="text" onClick={() => nav('/Products')}>Products</Button>
              <Button style={{color:"#444444"}} size="large" aria-labelledby="WhyVolunteer" variant="text" onClick={() => nav('/WhyVolunteer')}>Volunteer</Button>
              <Button style={{color:"#444444"}} size="large" aria-labelledby="Privacy" variant="text" onClick={() => nav('/Privacy')}><PolicyIcon></PolicyIcon></Button>
              <Button style={{color:"#444444"}} size="large" aria-labelledby="Contact" onClick={() => nav('/ContactUs')} variant='text'> <ContactSupportIcon></ContactSupportIcon></Button>        
              <Button style={{color:"#444444"}} size="large" aria-labelledby="About" onClick={() => nav('/AboutUs')} variant='text'><InfoIcon /></Button>   
            </div>     
          </Box> 
          <SearchSkills></SearchSkills>     
          <Button sx={{ marginright: "1rem", marginLeft: 'auto', backgroundColor:"navy" }} aria-label="Login" variant="contained" onClick={() => nav('/Login')} ><LoginIcon label="Members" /></Button>
          <IconButton
            sx={{ marginright: "1rem"}}
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onMouseDown={handleOpenNavMenu}
            onBlurCapture={toggleButton}
          >
            {isOpen ? <CloseIcon /> : <MenuIcon />}
          </IconButton> 
          <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: {
                  placeholder: 'close'
                },
              }}
            >
              <ButtonGroup orientation="vertical"
                aria-label="vertical outlined button group" onClick={handleCloseNavMenu}>
                <Button size="large" variant="contained" className="buttonStyle" onClick={() => nav('/Blogs')} style={{ color: "#444444", backgroundColor:"#e4f3e0",marginTop: "-8px" }}>Blogs</Button>
                <Button size="large" variant="contained" className="buttonStyle" onClick={() => nav('/Jobs')} style={{ color: "#444444", backgroundColor:"#e4f3e0", marginTop: "5px" }}>Jobs</Button>
                <Button size="large" variant="contained" className="buttonStyle" onClick={() => nav('/GainRewards')} style={{ color: "#444444",backgroundColor:"#e4f3e0", marginTop: "5px" }}>Rewards</Button>
                <Button size="large" variant="contained" className="buttonStyle" onClick={() => nav('/OurMission')} style={{ color: "#444444", backgroundColor:"#e4f3e0", marginTop: "5px" }}>Mission</Button>
                <Button size="large" variant="contained" className="buttonStyle" onClick={() => nav('/Products')} style={{ color: "#444444", backgroundColor:"#e4f3e0",marginTop: "5px" }}>Products</Button>
                <Button size="large" variant="contained" className="buttonStyle" onClick={() => nav('/WhyVolunteer')} style={{ color: "#444444",backgroundColor:"#e4f3e0", marginTop: "5px" }}>Volunteer</Button>
                <Button size="large" variant="contained" className="buttonStyle" onClick={() => nav('/SneakPeak')} style={{ color: "#444444",backgroundColor:"#e4f3e0", marginTop: "5px" }}><AndroidIcon></AndroidIcon></Button>
                <Button size="large" variant="contained" className="buttonStyle" onClick={() => nav('/Privacy')} style={{ color: "#444444",backgroundColor:"#e4f3e0", marginTop: "5px" }}><PolicyIcon></PolicyIcon></Button>
                <Button size="large" variant='contained' className="buttonStyle" onClick={() => nav('/ContactUs')} style={{ color: "#444444",backgroundColor:"#e4f3e0",marginTop: "5px", marginBottom: "-8px" }} ><ContactSupportIcon></ContactSupportIcon></Button>
              </ButtonGroup>
            </Menu>
        </Toolbar>
        </div>
        <div id="status-container" title="Server Status">
          <div className="status-squares">
            {Array(345).fill(0).map((_, index) => (
              <div className={`square ${isServerUp ? 'green' : 'red'}`} key={index}></div>
            ))}
          </div>
        </div>
      </AppBar>
        <SimpleSlide style={{ zIndex: 300 }} />
        {showButton && (
          <Button sx={{backgroundColor:"#e4f3e0",bottom: "20px", zIndex: "1000", marginLeft: "5px", borderTop: '1px solid #444444' }} variant="contained" value="home" onClick={scrollTop}>
            <ArrowUpwardIcon label="Members" /></Button>
        )}
    </>
  );
}
export default HomePageBar;