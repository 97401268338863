import React from 'react';
import EditTaskModal from './EditTaskModal';
import { Button, useMediaQuery, useTheme, Box, Typography, Paper, Container } from '@mui/material';

const Tasks = ({ tasks, delTask, taskEdited, user, setUpdatedTasks }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const TaskRow = (task, index) => {
    if (isMobile) {
      // Card-style display for mobile
      return (
        <Paper
          key={index}
          elevation={1}
          sx={{
            padding: 2,
            marginBottom: 2,
            borderRadius: 3,
            boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
          }}
        >
          <Typography variant="body1" gutterBottom>
            <strong>Creator:</strong> {task.createdBy}
          </Typography>
          <Typography variant="body1" gutterBottom>
            <strong>Task:</strong> {task.task}
          </Typography>
          <Typography variant="body1" gutterBottom>
            <strong>Assignee:</strong> {task.assignee}
          </Typography>
          <Typography variant="body1" gutterBottom>
            <strong>Status:</strong> {task.status}
          </Typography>
          {user === task.createdBy && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: 2,
              }}
            >
              <EditTaskModal tasks={task} setUpdatedTasks={setUpdatedTasks} taskEdited={taskEdited} />
              <Button
                variant="outlined"
                color="error"
                onClick={() => {
                  if (user === task.createdBy) {
                    delTask(task._id);
                  } else {
                    alert('You did not create this task');
                  }
                }}
              >
                Delete
              </Button>
            </Box>
          )}
        </Paper>
      );
    } else {
      // Table row display for desktop
      return (
        <tr key={index}>
          <td>{task.createdBy}</td>
          <td>{task.task}</td>
          <td>{task.assignee}</td>
          <td>{task.status}</td>
          {user === task.createdBy && (
            <td>
              <EditTaskModal tasks={task} setUpdatedTasks={setUpdatedTasks} taskEdited={taskEdited} />
              <Button
                variant="outlined"
                color="error"
                onClick={() => {
                  if (user === task.createdBy) {
                    delTask(task._id);
                  } else {
                    alert('You did not create this task');
                  }
                }}
              >
                Delete
              </Button>
            </td>
          )}
        </tr>
      );
    }
  };

  return (
    <Container
      sx={{
        maxWidth: isMobile ? '100%' : '800px',
        margin: 'auto',
        padding: isMobile ? 1 : 3,
      }}
    >
      {isMobile ? (
        <Box>
          {tasks.map((task, index) => TaskRow(task, index))}
        </Box>
      ) : (
        <Box
          sx={{
            backgroundColor: 'white',
            borderRadius: 3,
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
            overflow: 'hidden',
          }}
        >
          <table style={{ width: '100%', borderSpacing: 0 }}>
            <thead>
              <tr style={{ backgroundColor: '#f5f5f5', textAlign: 'left' }}>
                <th style={{ padding: '12px 16px', minWidth: '80px' }}>Creator</th>
                <th style={{ padding: '12px 16px', minWidth: '80px' }}>Task</th>
                <th style={{ padding: '12px 16px', minWidth: '80px' }}>Assignee</th>
                <th style={{ padding: '12px 16px', minWidth: '80px' }}>Status</th>
                <th style={{ padding: '12px 16px', minWidth: '100px' }}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {tasks.map((task, index) => TaskRow(task, index))}
            </tbody>
          </table>
        </Box>
      )}
    </Container>
  );
};

export default Tasks;
