import React, { useEffect, useState } from 'react';
import {
  Box,
  Fab,
  Tooltip,
  Avatar,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Typography,
  Grid,
  Paper,
  Modal,
  Button
  
} from '@mui/material';
import {
  CheckCircleOutline as CheckCircleOutlineIcon,
  CachedRounded as CachedRoundedIcon,
  Image as ImageIcon,
  Reply as ReplyIcon,
  Downloading as DownloadingIcon,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useLocation, useNavigate } from 'react-router-dom';
import { getInbox } from '../services/InboxService';
import { editTask, deleteTask, getUserTasks } from '../services/TaskService';
import LoginBarTop from './LoginBarTop';
import Footer from './Footer';
import CreateTask from './CreateTask';
import ChatComponent from './FrontEndChat/ChatComponent';
import RecordVideo from './RecordVideo';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
}));

const UserTasks = ({ removeData }) => {
  const nav = useNavigate();
  const location = useLocation();
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isMessageFormOpen, setMessageFormOpen] = useState(false);
  const [isCreateTaskOpen, setCreateTaskOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [imgUser, setImgUser] = useState();
  const state = location.state;
  const user = state?.realUser;
  const realUser = state?.realUser<

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const tasks = await getUserTasks(user);
        setRows(tasks);

        const inboxData = await getInbox({ user });
        setImgUser(inboxData[0]?.userImg[0]?.imgLink);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    if (user) fetchData();
    else nav('/');
  }, [user, nav]);

  const handleSendMessageForm = async (taskId) => {
    try {
      const task = rows.find((task) => task._id === taskId);
      await editTask({ ...task, message });
      setMessageFormOpen(false);
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const updateTaskStatus = async (taskId, status) => {

    try {
      const task = rows.find((task) => task._id === taskId);
      await editTask({ ...task, status });
      setRows((prev) => prev.map((t) => (t._id === taskId ? { ...t, status } : t)));
    } catch (error) {
      console.error('Error updating task status:', error);
    }
  };

  const deleteTaskHandler = async (taskId) => {
    try {
      await deleteTask(taskId);
      setRows((prev) => prev.filter((task) => task._id !== taskId));
    } catch (error) {
      console.error('Error deleting task:', error);
    }
  };

  const onDragEnd = (result) => {

    let status;
    let taskId = result.draggableId

    if (result.destination === null) {
      deleteTaskHandler(taskId);
      return;
    }else{
     status = result.destination.droppableId;
    }

    
    

    
    updateTaskStatus(taskId,status)
    const { source, destination } = result;
    if (!destination) return;
    const tasks = Array.from(rows);
    const [movedTask] = tasks.splice(source.index, 1);
    tasks.splice(destination.index, 0, movedTask);
    setRows(tasks);
  };

  const TaskColumn = ({ tasks, status, title, droppableId }) => (
    <Droppable droppableId={droppableId}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.droppableProps}
          style={{
            backgroundColor: snapshot.isDraggingOver ? '#e3f2fd' : '#f5f5f5',
            padding: '10px',
            borderRadius: '8px',
            minHeight: '300px',
          }}
        >
          <Typography variant="h6" style={{ marginBottom: '10px' }}>
            {title}
          </Typography>
          {tasks.map((task, index) => (
            <Draggable key={task._id} draggableId={task._id} index={index}>
              {(provided) => (
                <Box
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  sx={{
                    backgroundColor: 'white',
                    borderRadius: '8px',
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                    padding: '10px',
                    marginBottom: '10px',
                  }}
                >
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar src={task.imgLink || ''}>
                        <ImageIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={task.task}
                      secondary={task.message || 'No message'}
                    />
                    <Tooltip title="Mark as completed">
                      <Fab
                        color="primary"
                        size="small"
                        onClick={() => updateTaskStatus(task._id, 'Completed')}
                      >
                        <CheckCircleOutlineIcon />
                      </Fab>
                    </Tooltip>
                  </ListItem>
                </Box>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );

  return (
    <>
         <LoginBarTop realUser={realUser} ></LoginBarTop>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setCreateTaskOpen(true)}
        style={{ margin: '20px' }}
      >
        Create Task
      </Button>
   
      <Grid container spacing={2} style={{ marginTop: '20px' }}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Grid item xs={12} sm={6} md={3}>
            <TaskColumn
              tasks={rows.filter((task) => task.status === 'To Be Done')}
              status="To Be Done"
              title="To Be Done"
              droppableId="To Be Done"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TaskColumn
              tasks={rows.filter((task) => task.status === 'In Progress')}
              status="In Progress"
              title="In Progress"
              droppableId="In Progress"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TaskColumn
              tasks={rows.filter((task) => task.status === 'Completed')}
              status="Completed"
              title="Completed"
              droppableId="Completed"
            />
          </Grid>
        </DragDropContext>
        <Modal
        open={isCreateTaskOpen}
        onClose={() => setCreateTaskOpen(false)}
        aria-labelledby="create-task-modal"
        aria-describedby="create-task-modal-description"
      >
        <Box>
          <CreateTask user={user} imgUser={imgUser} />
        </Box>
      </Modal>
      </Grid>
      {removeData === 0 && <Footer />}
    </>
  );
};

export default UserTasks;
