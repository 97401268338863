import { useEffect, useState } from "react";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Tasks from './Tasks';
import TaskList from './TaskList';
import PdfUploader from './PdfUploader';
import Card from '@mui/material/Card';
import { CardContent } from '@mui/material';
import { useForm } from "react-hook-form";
import { TaskProvider } from './TaskContext';
import { createTask } from '../services/TaskService';
import { getAllTasks, deleteTask } from '../services/TaskService';
import { getInbox } from '../services/InboxService';
import { useTheme, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Button } from '@material-ui/core';

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const CreateTask = ({ user, imgUser, realUser, setUpdatedTasks }) => {
  const nav = useNavigate();
  if (realUser === null) {
    nav('/');
  }

  const { register, handleSubmit } = useForm();
  const [tasks, setTasks] = useState([]);
  const [isTaskEdited, setTaskEdited] = useState(false);
  const [users, setUsers] = useState([]);
  const [isMember, setIsMember] = useState([]);
  const [addTask, setAddTask] = useState(false);
  const [removeTask, setRemoveTask] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [personName, setPersonName] = useState([]);
  const [queue, setQueue] = useState([]);
  
  const SelectTasks = ['To Be Done', 'In Progress', 'Completed'];

  useEffect(() => {
    const processQueue = async () => {
      for (const action of queue) {
        await action();
      }
      setQueue([]);
    };

    if (queue.length > 0) {
      processQueue();
    }
  }, [queue]);

  useEffect(() => {
    getInbox({ user }).then((inboxes) => {
      const checkMembers = inboxes[0]?.members || [];
      const uniqueMembers = new Set();

      checkMembers.forEach((member) => {
        if (member.isMember === 1) {
          uniqueMembers.add(JSON.stringify(member));
        }
      });

      uniqueMembers.add(JSON.stringify({ 
        name: user, 
        isMember: 1, 
        projectId: "001", 
        member: user 
      }));

      setIsMember(Array.from(uniqueMembers).map(JSON.parse));
    });

    getAllTasks().then(setTasks);
  }, [addTask, removeTask, isTaskEdited]);

  const enqueueAction = (action) => {
    setQueue((prevQueue) => [...prevQueue, action]);
  };

  const delTask = (taskId) => {
    enqueueAction(async () => {
      try {
        await deleteTask(taskId);
        setRemoveTask(taskId);
        setTaskEdited((prev) => !prev);
      } catch (error) {
        console.error('Error deleting task:', error);
      }
    });
  };

  const onSubmit = (data) => {
    const newData = { 
      createdBy: user, 
      status: data.status[1], 
      task: data.task, 
      assignee: data.assignee[0].member, 
      message: "", 
      imgLink: imgUser 
    };

    

    enqueueAction(async () => {
      try {
        const sendTask = await createTask(newData);
        if (sendTask) {
          setAddTask((prev) => !prev);
          setTaskEdited((prev) => !prev);
          setUpdatedTasks((prev) => !prev);
        }
      } catch (error) {
        console.error('Error sending task:', error);
      }
    });
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setPersonName(typeof value === 'string' ? value.split(',') : value);
  };

  return (
    <Card 
      style={{
        margin: isMobile ? '10px' : '20px auto',
        padding: isMobile ? '10px' : '20px',
        maxWidth: isMobile ? '100%' : '800px',
        float: isMobile ? '' : 'left'
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <CardContent>
          <InputLabel id="task">New task</InputLabel>
          <TextField 
            fullWidth 
            style={{ marginTop: "5px" }} 
            {...register("task")} 
            className="form-control" 
            required 
          />
          <InputLabel style={{ marginTop: "5px" }} id="assignee">Assignee</InputLabel>
          <Select 
            fullWidth 
            {...register("assignee")} 
            multiple 
            value={personName} 
            onChange={handleChange}
            sx={{ marginBottom: "1rem" }}
          >
            {isMember.map((name, index) => (
              <MenuItem
                key={index}
                value={name}
                style={getStyles(name, personName, theme)}
              >
                {name.member}
              </MenuItem>
            ))}
          </Select>
          <InputLabel style={{ marginTop: "5px" }} id="status">Status</InputLabel>
          <Select 
            fullWidth 
            {...register("status")} 
            multiple 
            value={personName} 
            onChange={handleChange}
            sx={{ marginBottom: "1rem" }}
          >
            {SelectTasks.map((status, index) => (
              <MenuItem
                key={index}
                value={status}
                style={getStyles(status, personName, theme)}
              >
                {status}
              </MenuItem>
            ))}
          </Select>
          <TaskProvider>
            <PdfUploader hidden />
            <Button 
              style={{ float: 'right', marginTop: '10px' }} 
              type="submit" 
              variant="outlined"
            >
              Add Task
            </Button>
            <TaskList />
          </TaskProvider>
          
          <Tasks 
            tasks={tasks} 
            user={user} 
            delTask={delTask} 
            setUpdatedTasks={setUpdatedTasks} 
          />
        </CardContent>
      </form>
    </Card>
  );
};

export default CreateTask;
