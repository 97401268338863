import React, { useState } from 'react';
import AvatarImg from '../images/avatar.webp'
import Card from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia'
import CardContent from '@mui/material/CardContent'
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton'
import SendIcon from '@mui/icons-material/Send'
import HomePageBar from './HomePageBar';
import { createNewUser } from '../services/UserService'
import { createInbox, uploadRewards } from '../services/InboxService'
import { useForm } from "react-hook-form";
import { updateUserImage } from '../services/InboxService';
import { useNavigate } from 'react-router-dom';
import { updateInbox } from '../services/InboxService';
import Footer from './Footer';
const CreateUser = () => {
    const [setName] = useState("")
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const [confirmPassword, setConfirmPassword] = useState('')
    const [passwordError, setPasswordError] = useState(false)
    const [password, setPassword] = useState('')
    const [isFilled, setIsFilled] = useState(false);
    const onChange = (e) => {
        const newValue = e.target.value;
        if (!newValue.match(/\s/g)) {
            setError("");
        } else {
            setError("Unforbidden Space");
        }
        setName(newValue);

        const inputValue = e.target.value.toLowerCase();  // Convert to lowercase for case-insensitive comparison
        if (inputValue === "shit" || inputValue === "fuck" || inputValue === "wank" || inputValue === "<script>") {
            setIsFilled(true);
        } else {
            setIsFilled(false);
        }
    };
    const navigate = useNavigate()
    const { register, handleSubmit } = useForm();
    const onSubmit = async (data, e) => {
        if (password !== confirmPassword) {
            setPasswordError(true);
            return;
        }
        setPasswordError(false);
        setLoading(true);
        let newUser = { user: data.username, email: data.email }
        try {
            await createNewUser(data).then(response => {
            });
            await createInbox(newUser).then(response => {
                e.target.reset();
                var link = [AvatarImg],
                    valueToUse = link[Math.floor(Math.random() * link.length)];
                let location = newUser.location || "";
                let skills = newUser.skills || "";
                let setDataImg = { user: data.username, _id: "", imgLink: valueToUse, name: "edit name", comments: "update Status", location: location, skills: skills };
                updateUserImage(setDataImg).then(success => {
                });
                let createdBy = "JamesBest"
                let message = "Welcome to Local Project"
                let userImg = AvatarImg
                let realUser = "JamesBest"
                const theTime = new Date().toLocaleTimeString();
                let messageId = Math.random(123456);
                const newData = { user: data.username, read: false, message: userImg + "  " + createdBy + " : " + message + "  @" + theTime, createdBy: realUser, id: messageId, messageId: messageId, createdByImg: userImg };
                updateInbox(newData);
            });
            runRewards(data);
            setLoading(false);
            navigate(`/Login/`, { state: { user: data.username, password: data.password} });
        } catch (error) {
            console.error(error);
            setError('Please try another Username');
            setLoading(false);
        }
    };
    function runRewards(data) {
        let ammount = 21;
        let rewards = { user: data.user, rewards: ammount };
        uploadRewards(rewards);
    }
    return (
        <>
            <HomePageBar></HomePageBar>
            <Card sx={{ maxWidth: "350px", justifyContent: "center", marginLeft: "auto", marginRight: "auto",marginBottom:"350px", marginTop:"3rem"}}>
            <CardMedia className='imgLogin'
                  component="Img"
                  alt="Login Pic"
                  style={{ objectFit: "cover", height: '15rem', marginBottom: "1.8rem", marginTop: "1.8rem" }}
                />   
                <CardContent>
                    <form id="formId" onSubmit={handleSubmit(onSubmit)}>
                        <div className={`form-group ${isFilled ? 'form-control has-content' : ''}`}>
                            <TextField {...register("username")} required label="Username" variant="outlined"
                                fullWidth
                                onkeypress="return event.charCode != 32"
                                inputProps={{ maxLength: 20, minLength: 5 }}
                                onChange={onChange}
                                helperText={error}
                                error={!!error} placeholder="Username" name="username" id="username" />
                        </div>
                        {passwordError && <div style={{ color: 'red', marginTop: '5px' }}>Passwords do not match</div>}
                        <div className={`form-group ${isFilled ? 'form-control has-content' : ''}`}>
                            <TextField
                                {...register("password")}
                                fullWidth
                                required
                                label="Create Password"
                                variant="outlined"
                                type="password"
                                id="password"
                                inputProps={{ maxLength: 20, minLength: 8 }}
                                value={password}
                                onChange={(event) => setPassword(event.target.value)}
                                placeholder="Create Password"
                                style={{ marginTop: "10px" }} />
                        </div>
                        <div className={`form-group ${isFilled ? 'form-control has-content' : ''}`}>
                            <TextField
                                fullWidth
                                required
                                label="Confirm Password"
                                variant="outlined"
                                type="password"
                                inputProps={{ maxLength: 20, minLength: 8 }}
                                value={confirmPassword}
                                onChange={(event) => setConfirmPassword(event.target.value)}
                                placeholder="Confirm Password"
                                style={{ marginTop: "10px" }} />
                        </div>
                        <div className={`form-group ${isFilled ? 'form-control has-content' : ''}`}>
                            <TextField fullWidth style={{ marginTop: "10px" }} label="Email" variant="outlined"
                                {...register("email")} inputProps={{ maxLength: 35, minLength: 8 }} required name="email" id="email" type="email" placeholder="Email" />
                        </div>
                        <div className={`form-group ${isFilled ? 'form-control has-content' : ''}`}>
                            <LoadingButton style={{  backgroundColor: "#e4f3e0", color: "#444444", marginTop: "1rem" }}
                                fullWidth
                                className="buttonStyle"
                                variant="contained"
                                type="submit"
                                checked={loading}
                                onChange={() => setLoading(!loading)}
                                name="loading"
                                color="primary"
                                endIcon={<SendIcon/>}
                                loading={loading}
                                loadingPosition="end"
                            >Create</LoadingButton>
                        </div>
                        <div className={`form-group ${isFilled ? 'form-control has-content' : ''}`}>
                        </div>
                    </form>
                </CardContent>
            </Card>
       <Footer></Footer></>
    )
}
export default CreateUser